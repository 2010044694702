
















































































import Vue from "vue";

import Header from "@/components/Header.vue";
import Search from "@/components/Search.vue";

import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "Dashboard",
  components: {
    "b-header": Header,
    search: Search
  },
  computed: {
    ...mapGetters("auth", ["me"]),
    ...mapGetters("clients", ["clientsCount"]),
    ...mapGetters("companies", ["companiesCount"]),
    ...mapGetters("orders", ["ordersCount"]),
    ...mapGetters("news", ["newsList"])
  },
  data() {
    return {
      is_queen: process.env.VUE_APP_IS_QUEEN
    };
  },
  created() {
    this.getClientsList(1);
    this.getCompaniesList(1);
    this.getOrdersList(1);
    this.getNewsList();
  },
  methods: {
    ...mapActions("clients", ["getClientsList"]),
    ...mapActions("companies", ["getCompaniesList"]),
    ...mapActions("orders", ["getOrdersList"]),
    ...mapActions("news", ["getNewsList"])
  }
});
