import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Customers from "@/views/Customers.vue";
import CustomersEdit from "@/views/CustomersEdit.vue";
import Companies from "@/views/Companies.vue";
import CompaniesEdit from "@/views/CompaniesEdit.vue";
import Orders from "@/views/Orders.vue";
import OrdersEdit from "@/views/OrdersEdit.vue";
import Calendar from "@/views/Calendar.vue";
import Maps from "@/views/Maps.vue";
import Stats from "@/views/Stats.vue";
import Targets from "@/views/Targets.vue";
import Accounting from "@/views/Accounting.vue";
import AccountingDetails from "@/views/AccountingDetails.vue";
import Planning from "@/views/Planning.vue";
import AppointmentsCalendar from "@/views/AppointmentsCalendar.vue";
import TasksList from "@/views/TasksList.vue";
import News from "@/views/News.vue";
import TradeFair from "@/views/TradeFair.vue";
import TradeFairEdits from "@/views/TradeFairEdits.vue";

import settingsRoutes from "./settings";

import loginBeforeEnter from "./loginBeforeEnter";
import authAdmin from "./authAdmin";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/clienti",
    name: "Customers",
    component: Customers,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/clienti/:id",
    name: "CustomersEdit",
    component: CustomersEdit,
    props: true,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/aziende",
    name: "Companies",
    component: Companies,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/aziende/:id",
    name: "CompaniesEdit",
    component: CompaniesEdit,
    props: true,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/ordini",
    name: "Orders",
    component: Orders,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/ordini/:id",
    name: "OrdersEdit",
    component: OrdersEdit,
    props: true,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/calendario",
    name: "Calendar",
    component: Calendar,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/mappe",
    name: "Maps",
    component: Maps,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/statistiche",
    name: "Stats",
    component: Stats,
    beforeEnter: authAdmin
  },
  {
    path: "/programmazione",
    name: "Targets",
    component: Targets,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/amministrazione",
    name: "Accounting",
    component: Accounting,
    beforeEnter: authAdmin
  },
  {
    path: "/amministrazione/:id",
    name: "AccountingDetails",
    component: AccountingDetails,
    beforeEnter: authAdmin,
    props: true
  },
  {
    path: "/planning",
    name: "Planning",
    component: Planning,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/appuntamenti",
    name: "AppointmentsCalendar",
    component: AppointmentsCalendar,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/tasks",
    name: "TasksList",
    component: TasksList,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/news",
    name: "News",
    component: News,
    beforeEnter: loginBeforeEnter
  },
  {
    path: "/campionario",
    name: "TradeFair",
    component: TradeFair,
    beforeEnter: authAdmin
  },
  {
    path: "/campionario/:id",
    name: "TradeFairEdits",
    component: TradeFairEdits,
    beforeEnter: authAdmin,
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.concat(settingsRoutes)
});

export default router;
